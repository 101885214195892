import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
import "hammerjs";
import { AppModule } from "./app/app.module";
import { environment } from "./environments/environment";

declare var __webpack_public_path__: string;
__webpack_public_path__ = "";

if (environment.subDomen == "paynote") {
  document.write(
    "<noscript>\n" +
      '  <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-W7RK4F" height="0" width="0" style="display:none;visibility:hidden"></iframe>\n' +
      "</noscript>"
  );
  document.write(
    '<script async src="https://www.googletagmanager.com/gtag/js?id=AW-667011747"></script>'
  );
  document.write(
    "<script>\n" +
      "  window.dataLayer = window.dataLayer || [];\n" +
      "  function gtag(){dataLayer.push(arguments);}\n" +
      "  gtag('js', new Date());\n" +
      "  gtag('config', 'AW-667011747');\n" +
      "</script>"
  );
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.log(err));
