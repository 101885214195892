<ng-progress color="purple" [thick]="true"></ng-progress>
<div class="all-wrapper menu-side with-pattern">
  <div class="auth-box-w m-auto" *ngIf="isLinkValid">
    <div class="logo-w">
      <a routerLink="/"
      ><img alt="" src="assets/img/Paynote_-_New_Blue.svg"
      /></a>
    </div>
    <div>
      <h4 class="auth-header" style="margin-bottom: 0px">Set Password</h4>
      <form
        name="form"
        autocomplete="nope"
        (ngSubmit)="f.form.valid"
        [formGroup]="setupPasswordForm"
        #f="ngForm"
        class="form-validation"
      >
        <label
          style="font-size: 12px; margin-bottom: 10px"
        ></label>
        <h6 style="font-size: 14px">Password Requirements:</h6>
        <ul style="font-size: 13px">
          <li>Have at least 8 characters</li>
          <li>Have at least one lower case letter</li>
          <li>Have at least one capital letter</li>
          <li>Have at least one number</li>
        </ul>
        <div
          class="form-group"
          [ngClass]="{
            'has-error has-danger':
              setupPasswordForm.get('password')?.errors &&
              (setupPasswordForm.get('password').dirty ||
                setupPasswordForm.get('password').touched)
          }"
        >
          <label
          >Password
            <i
              ngbTooltip="{{ messages.get('PASSWORD_REQUIREMENTS') }}"
              aria-hidden="true"
              class="fa fa-question-circle"
              placement="bottom"
            >
            </i>
          </label>
          <input
            name="password"
            class="form-control"
            placeholder="Password"
            type="password"
            formControlName="password"
            required
            minlength="8"
            autocomplete="nope"
            (keyup)="setupPasswordForm.get('cpassword').patchValue(setupPasswordForm.get('cpassword').value)"
          />
          <div
            class="pre-icon os-icon os-icon-fingerprint top-33"
            style="top: 32px"
          ></div>
          <div
            *ngIf="
              setupPasswordForm.get('password')?.errors &&
              (setupPasswordForm.get('password').dirty ||
                setupPasswordForm.get('password').touched)
            "
            class="help-block form-text with-errors form-control-feedback"
          >
            <span *ngIf="setupPasswordForm.get('password')?.errors.minlength"
            >Password must be at least 8 characters long.</span
            >
            <span *ngIf="setupPasswordForm.get('password')?.errors.required"
            >Password is required</span
            >
            <span *ngIf="setupPasswordForm.get('password')?.errors.pattern"
            >Password does not match requirements</span
            >
          </div>
          <ng9-password-strength-bar
            [passwordToCheck]="setupPasswordForm.value.password"
            [barLabel]="barLabel"
            [barColors]="myColors"
          >
          </ng9-password-strength-bar>
        </div>

        <div
          class="form-group"
          [ngClass]="{
            'has-error has-danger':
              setupPasswordForm.get('cpassword')?.errors &&
              (setupPasswordForm.get('cpassword').dirty ||
                setupPasswordForm.get('cpassword').touched)
          }"
        >
          <label>Confirm Password</label>
          <input
            name="cpassword"
            class="form-control"
            placeholder="Confirm Password"
            type="password"
            formControlName="cpassword"
            required
            autocomplete="off"
            validateEqual="password"
          />
          <div class="pre-icon os-icon os-icon-fingerprint top-33"></div>
          <div
            *ngIf="
              setupPasswordForm.get('cpassword')?.errors &&
              (setupPasswordForm.get('cpassword').dirty ||
                setupPasswordForm.get('cpassword').touched)
            "
            class="help-block form-text with-errors form-control-feedback"
          >
            Password mismatch
          </div>
        </div>

        <div
          *ngIf="errorService.isError"
          class="alert alert-danger"
          role="alert"
          [innerHTML]="errorService.errorMessage"
        ></div>
        <div
          *ngIf="errorService.isSuccess"
          class="alert alert-success"
          role="alert"
          [innerHTML]="errorService.successMessage"
        ></div>
        <div class="text-center" style="margin-top: 20px">
          <button
            [disabled]="loading || !setupPasswordForm.valid"
            type="submit"
            class="btn btn-primary"
            style="display: inline-block"
            (click)="sendPassword()"
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  </div>
  <div *ngIf="!isLinkValid">
    <div class="row">
      <div class="text-center col-lg-8" style="margin: auto;">
        <h4>Password Link has been expired</h4>
      </div>
    </div>
  </div>
</div>
