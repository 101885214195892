import {Component, OnInit, ViewChild} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthenticationService} from '../../auth.service';
import {ErrorService} from '../../_services/error.service';
import {UserService} from '../../_services/user.service';
import {JqueryService} from '../../_services/jquery.service';
import {environment} from '../../../environments/environment';
import {MessagesService} from '../../_services/messages.service';
import {TopAlertsService} from '../../_services/top-alerts.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {validatePhone} from '../../_vaidators/phone';
import {validateFullName} from '../../_vaidators/fullName';
import {validateEmail} from '../../_vaidators/email';
import {Utility} from '../../_helpers/utility';

@Component({
  selector: 'password-set',
  templateUrl: './password-set.component.html',
  styleUrls: ['./password-set.component.css']
})
export class PasswordSetComponent implements OnInit {

  public host = environment.host;
  public loading = false;
  // public setupPasswordForm: any = {};
  public objPassInfo: any = {};
  public barLabel = '';
  public myColors = ['#DD2C00', '#FF6D00', '#FFD600', '#AEEA00', '#00C853'];
  public setupPasswordForm: FormGroup;
  public isLinkValid: boolean = true;

  constructor(
    private http: HttpClient,
    public errorService: ErrorService,
    public userService: UserService,
    public messages: MessagesService,
    public jqueryService: JqueryService,
    private activatedRouter: ActivatedRoute,
    public topAlertsService: TopAlertsService,
    public utility: Utility,
    public authenticationService: AuthenticationService,
    private router: Router,
    private _formBuilder: FormBuilder,
  ) {}

  ngOnInit() {
    // this.setupPasswordForm.token = this.activatedRouter.snapshot.paramMap.get('create_password_token');

    this.errorService.clearAlerts();
    this.jqueryService.removeSelector('.modal-backdrop.fade');
    this.jqueryService.removeSelector('.modal.fade.show.d-block');

    this.setupPasswordForm = this._formBuilder.group({
      password: [
        "",
        Validators.compose([
          Validators.required,
          Validators.minLength(8),
          Validators.pattern(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/),
        ]),
      ],
      cpassword: [
        "",
        Validators.compose([Validators.required, Validators.minLength(8)]),
      ],
      token: [this.activatedRouter.snapshot.paramMap.get('create_password_token')],
    });
    this.verifyPassword();
  }

  verifyPassword() {
    const objRequest = {
      token: this.setupPasswordForm.get('token').value
    };
    this.http.get<any>(this.host + '/user/password/create/verify', {params: objRequest})
      .subscribe(
        response => {
          if (response.success) {
            this.objPassInfo = response;
            this.isLinkValid = true;
          }
        },
        errResponse => {
          this.loading = false;
          this.isLinkValid = false;
          if ( errResponse.error ) {
            this.errorService.getMessageError( errResponse.error );
          }
        }
      );
  }

  sendPassword() {
    const objRequest = {
      token: this.setupPasswordForm.get('token').value,
      password: this.setupPasswordForm.get('password').value,
      cpassword: this.setupPasswordForm.get('cpassword').value
    };
    this.http.post<any>(this.host + '/user/password/create/set', objRequest)
      .subscribe(
        response => {
          if (response.success) {
            this.topAlertsService.popToast('success', 'Success', this.messages.get('YOUR_PASSWORD_HAS_BEEN_SUCCESSFULLY_SET'));
            this.loading = true;
            localStorage.setItem('currentUserV2', JSON.stringify(response));
            this.userService.initUser();
            this.userService.retrieveUserLimits();
            this.router.navigate(['/transactions']);
          }
        },
        errResponse => {
          this.loading = false;
          if ( errResponse.error ) {
            this.errorService.getMessageError( errResponse.error );
          }
        }
      );
  }
}
