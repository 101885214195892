<ng-progress color="purple"></ng-progress>
<div class="content-box">
  <div class="element-wrapper">
    <div class="element-box col-9" style="margin: auto;">
      <h5 class="element-header">Welcome</h5>
      <h3 class="text-center">Thanks for confirming your email address!</h3>
<!--      <div class="text-center" style="margin-top: 30px;">
        <div [innerHTML]="messages.get('EMAIL_CONFIRMED_SUCCESSFULLY')"></div>
      </div>-->
      <div class="text-center" style="margin-top: 30px;">
        <a (click)="goToDashboard()" class="btn btn-primary btn-rounded" style="color: white">Go To Dashboard</a>
      </div>
    </div>
  </div>
</div>
