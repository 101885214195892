<ng-http-loader [backgroundColor]="'#047bf8'" [spinner]="spinkit.skWave" [debounceDelay]="100" [minDuration]="300" [filteredUrlPatterns]="utility.getExludedLoadingUrls()">
</ng-http-loader>
<div *ngIf="userService.warningMessages.length && userService.canShowVerifyMicroDeposit && permissionsService.isOnlyRead()" id="verifyMicroDeposit">
    <div *ngFor="let objWarMessage of userService.warningMessages">
        <div id="{{objWarMessage.action}}" role="alert" (click)="callAction( objWarMessage )" class="text-center infoMessage">
            {{objWarMessage.message}}
            <a href="javascript:void(0)" style="color: wheat;" (click)="openPlaidModal()" *ngIf="objWarMessage.action == 'verify_funding_source'">add your bank instantly</a>
        </div>
    </div>
</div>

<div *ngIf="!userService.isAdmin() && !userService.isSuperAdmin()">
    <div id="sandbox" class="wrap-user-messages-in-blue-block" style="background-color: #ffb811"
         *ngIf="userService.isSubDomenSandBox()">
          <span>
        Your are in Sandbox mode
      </span>
    </div>
    <div id="setupYouProfile" class="wrap-user-messages-in-blue-block"
         *ngIf="showOnboardingSteps()"
        (click)="goToNextStep()">
        <span>
            Welcome to your Paynote dashboard! Let’s complete your setup to start processing ACH payments. &nbsp;<i class="fa fa-long-arrow-right"></i>
        </span>
    </div>
    <div id="verifiedStatus" class="wrap-user-messages-in-blue-block"
         *ngIf="userService.getAppStatus() == 'Dwolla Verified'">
            <span>
          Great news! Onboarding is complete, and your account is verified. Our Paynote team is preparing your application. Questions? Call (888) 998-2439 or email paynote@seamlesschex.com.
        </span>
    </div>
    <div id="appSentStatus" class="wrap-user-messages-in-blue-block"
         *ngIf="userService.getAppStatus() == 'App Sent'">
            <span>
          ACH Processing Agreement Sent! Please check your email to review and sign the agreement to activate your plan. Questions? Call (888) 998-2439 or email paynote@seamlesschex.com.
        </span>
    </div>
    <div id="appReceivedStatus" class="wrap-user-messages-in-blue-block"
         *ngIf="userService.getAppStatus() == 'App Received'">
            <span>
          Your account is being activated. This process should take 1 business day. Questions? Call (888) 998-2439 or email paynote@seamlesschex.com.
        </span>
    </div>
<!--    <div id="addBankStatement" class="wrap-user-messages-in-blue-block"-->
<!--        *ngIf="userService.isMerchant() && !userService.isHavePlan() && userService.isPasswordSet() && userService.isHaveBankAccount() && !userService.isBankStatementVerified() && !userService.isBankStatementUploaded() && !userService.isFilledInfoForDwolla() && permissionsService.isOnlyRead()"-->
<!--        (click)="openDialogModal(uploadBankStatement)">-->
<!--        <span>-->
<!--          <span [innerHTML]="messages.get('ACCOUNT_HAS_RETRY_STATUS')"></span> &nbsp;<i class="fa fa-long-arrow-right"></i>-->
<!--        </span>-->
<!--    </div>-->

    <div id="addBankStatement" class="wrap-user-messages-in-blue-block"
        *ngIf="userService.isMerchant() && userService.isPasswordSet() && userService.isHaveBankAccount() && !userService.isBankStatementVerified() && userService.isBankStatementUploaded() && !userService.isFilledInfoForDwolla() && permissionsService.isOnlyRead()"
         [routerLink]="['/account/documents']">
        <span>
          Documents being reviewed. Click here to view status &nbsp;<i class="fa fa-long-arrow-right"></i>
        </span>
    </div>
    <div id="addBankStatement" class="wrap-user-messages-in-blue-block"
        *ngIf="userService.isMerchant() && userService.isPasswordSet() && userService.getOnboardingStep() === 5 && !userService.isFilledInfoForDwolla() && permissionsService.isOnlyRead()"
        (click)="jqueryService.showModal('.onboarding-in-dwolla-modal', {backdrop: 'static', keyboard: false})">
        <span>
          <span [innerHTML]="messages.get('ACCOUNT_HAS_RETRY_STATUS')"></span> &nbsp;<i class="fa fa-long-arrow-right"></i>
        </span>
    </div>
    <div id="setupProfileForClient" class="wrap-user-messages-in-blue-block" *ngIf="userService.isClient() && userService.isPasswordSet() && !userService.isFilledInfoForDwolla() && permissionsService.isOnlyRead()" (click)="checkCanClientUpdateToMerchant()">
        <span>
       <span [innerHTML]="messages.get('UPGRADE_CLIENT_ACCOUNT')"></span>&nbsp;<i class="fa fa-long-arrow-right"></i>
        </span>
    </div>
    <div id="setupPassword" class="wrap-user-messages-in-blue-block" *ngIf="userService.isCustomer() && !userService.isPasswordSet() && permissionsService.isOnlyRead()" (click)="showPopup('.password-setup-modal', '#password');">
        <span>
      Secure you Account. Create a password.
    </span>
    </div>

    <div id="accountRetryStatus" class="wrap-user-messages-in-blue-block" *ngIf="userService.isDwollaRetry() && permissionsService.isOnlyRead()"
            (click)="jqueryService.showModal('.onboarding-in-dwolla-modal', {backdrop: 'static', keyboard: false})">
        <span>
              <span [innerHTML]="messages.get('ACCOUNT_HAS_RETRY_STATUS')">
                  Oops! there was an issue with the information provided. Please review the details below and update here
              </span> &nbsp;<i class="fa fa-long-arrow-right"></i>
        </span>
        <div>
            <div *ngFor="let reasons of docsFailureReasons; let i = index">
                <span [innerHTML]="userService.DOC_FAILURE_REASONS[reasons]" class="fail-reason"></span>
                <br *ngIf="i < docsFailureReasons.length - 1">
            </div>
        </div>
    </div>

    <div id="accountLoginRequired" class="wrap-user-messages-in-blue-block"
            *ngIf="userService.isItemLoginRequired() && userService.canShowPopupReSyncBA() && permissionsService.isOnlyRead() && plaidService.isHaveUpdateLinkToken()"
            (click)="startUpdateBankAccount()">
        <span>
       <span [innerHTML]="messages.get('ACCOUNT_ITEM_LOGIN_REQUIRED')"></span> &nbsp;<i class="fa fa-long-arrow-right"></i>
        </span>
    </div>

    <div id="accountDownloadStatus" class="wrap-user-messages-in-blue-block" *ngIf="(userService.isDwollaDocument() && !userService.isHaveDocumentInPending() || ( userService.isNeedUploadDbaDoc() && !userService.isDwollaVerified() )) && permissionsService.isOnlyRead()"
        (click)="openDialogModal(addDocumentDialog, true)">
        <span>
<!--            <span [innerHTML]="messages.get('ACCOUNT_HAS_DOWNLOAD_STATUS')"></span>-->
            <span><b>Oops!</b> there was an issue with the information provided. Please review the details below and update here</span> &nbsp;<i class="fa fa-long-arrow-right"></i>
        </span>
        <div>
            <div *ngFor="let reasons of docsFailureReasons; let i = index">
                <span [innerHTML]="userService.DOC_FAILURE_REASONS[reasons]" class="fail-reason"></span>
            </div>
        </div>
    </div>

    <div id="accountDownloadPendingStatus" class="wrap-user-messages-in-blue-block" *ngIf="userService.isDwollaDocument() && userService.isHaveDocumentInPending() && permissionsService.isOnlyRead()" (click)="userService.redirectJustSimple('/account/documents')">
        <span>
      <span [innerHTML]="messages.get('ACCOUNT_HAS_DOWNLOAD_PENDING_STATUS')"></span> &nbsp;<i class="fa fa-long-arrow-right"></i>
        </span>
    </div>

    <div id="accountSuspendedStatus" class="wrap-user-messages-in-blue-block" *ngIf="userService.isDwollaSuspended() && permissionsService.isOnlyRead()">
        <span>
      <span [innerHTML]="messages.get('ACCOUNT_HAS_SUSPENDED_STATUS')"></span>
        </span>
    </div>

<!--    <div id="onboardingIsComplete" class="wrap-user-messages-in-blue-block"-->
<!--            *ngIf="userService.isMerchant() && userService.isDwollaVerified() && userService.onboardingIsComplete() && permissionsService.isOnlyRead()">-->
<!--        <span>-->
<!--      <span [innerHTML]="messages.get('METCHANT_ONBOARDING_IS_COMPLETE')"></span>-->
<!--        </span>-->
<!--    </div>-->
    <!--temporarily tern off-->
    <!--  <div id="ADDITIONAL_VERIFICATION_IS_REQUIRED" class="wrap-user-messages-in-blue-block" *ngIf="isShowAdditionalVerificationCode() && permissionsService.isOnlyRead()"
         (click)="callAction({action:'verify_voice_code'})">
      <span>
        <span [innerHTML]="messages.get('ADDITIONAL_VERIFICATION_IS_REQUIRED')"></span>
      </span>
    </div>-->

    <div id="accountUnverifiedStatus" style="cursor: warning;" class="wrap-user-messages-in-blue-block" *ngIf="!userService.isEmailConfirmation() && permissionsService.isOnlyRead() && !userService.isPartner() && !userService.isRep() && !userService.isCustomer()">
        <span>
      <span [innerHTML]="messages.get('ACCOUNT_HAS_UNVERIFIED_EMAIL')"></span>&nbsp;
        <span class="resendEmail" (click)="profileInfoVerifyEmail()">Resend</span>
        </span>
    </div>

    <div id="clientNotHaveBankAccount" style="cursor: warning;" class="wrap-user-messages-in-blue-block" *ngIf="userService.isclientNotHaveBankAccount() && permissionsService.isOnlyRead()" (click)="newShowClientFS();">
        <span><span [innerHTML]="messages.get('TITLE_ADD_BANK_ACCOUNT_IS_CLIENT')"></span></span>
    </div>

    <div id="addBeneficialOwner" class="wrap-user-messages-in-blue-block" *ngIf="!userService.isSubDomenSandBox() && userService.isFilledInfoForDwolla()
       && !userService.isDwollaRetry() && !userService.isDwollaDocument() && !userService.isDwollaSuspended()
       && userService.isRequestAddBeneficialOwner && permissionsService.isOnlyRead()" (click)="openDialogModal(addBeneficialOwner)">
        <!--(click)="userService.redirectJustSimple('/account', true, {tab_name: 'profile'})">-->
        <span>
      <span [innerHTML]="messages.get('NOTIFICATION_ADD_BENEFICIAL_OWNER')"></span>
        </span>
    </div>

    <!--  UPLOAD_BENEFICIAL_OWNER_ID  -->
    <div id="UPLOAD_BENEFICIAL_OWNER_ID" class="wrap-user-messages-in-blue-block" *ngIf="userService.isUploadBenefisiarDocument() && !userService.isRequestVerifydBeneficialOwner && !userService.isRequestCertifyBeneficialOwner && permissionsService.isOnlyRead()"
        (click)="openDialogModal(addDocumentDialog); getBeneficialOwner();">
        <span>
      <span [innerHTML]="messages.get('UPLOAD_BENEFICIAL_OWNER_ID')"></span> &nbsp;<i class="fa fa-long-arrow-right"></i>
        </span>
    </div>

    <!--  CERTIFY_BENEFICIAL_OWNER -->
    <div id="sertifyBeneficialOwner" class="wrap-user-messages-in-blue-block" *ngIf="userService.isFilledInfoForDwolla() && userService.isRequestCertifyBeneficialOwner
       && !userService.isRequestVerifydBeneficialOwner && !userService.isRequestAddBeneficialOwner && !userService.isDwollaDocument() && permissionsService.isOnlyRead()" (click)="certifyBeneficialOwner()">
        <span>
      <span [innerHTML]="messages.get('NOTIFICATION_CERTIFY_BENEFICIAL_OWNER')"></span>
        </span>
    </div>

    <!--  VERIFY_BENEFICIAL_OWNER   -->
    <div id="verifyBeneficialOwner" class="wrap-user-messages-in-blue-block" *ngIf="userService.isFilledInfoForDwolla()
       && userService.isRequestVerifydBeneficialOwner && !userService.isRequestAddBeneficialOwner && !userService.isDwollaDocument() && permissionsService.isOnlyRead()" (click)="openDialogModal(addDocumentDialog); getBeneficialOwner();">
        <span>
      <span [innerHTML]="messages.get('NOTIFICATION_VERIFY_BENEFICIAL_OWNER')"></span>
        </span>
    </div>

    <div id="isPlanPaymentFailed" class="wrap-user-messages-in-red-block" *ngIf="userService.isPlanPaymentFailed()" (click)="userService.redirectJustSimple('/account/funding_sources/add')">
        <span>
      <span [innerHTML]="messages.get('NOTIFICATION_PLAN_PAYMENT_FAILED')"></span>
        </span>
    </div>

    <div id="isHavePersonalSignupLock" class="wrap-user-messages-in-red-block" *ngIf="userService.isHavePersonalSignupLock()">
        <span>
      <span [innerHTML]="messages.get('NOTIFICATION_HAVE_PERSONALSIGNUP_LOCK')"></span>
        </span>
    </div>

    <div id="isHaveReachedLimits" class="wrap-user-messages-in-red-block" *ngIf="userService.isHaveReachedLimits() && !userService.isPartner() && !userService.isRep()">
        <span>
      <span [innerHTML]="userService.objPlanLimits.alarmMessage"></span>
        </span>
    </div>

<!--    Merchant Onboarding Progress Bar-->
    <div
        class="stepper stepper-pills"
        *ngIf="showOnboardingSteps()">
        <br>
        <div class="stepper-nav">
            <!-- Step 1 -->
<!--            <div class="stepper-item" [ngClass]="{ 'current': onboardingStep === 1, 'completed': onboardingStep > 1 }">-->
<!--                <div class="stepper-icon">-->
<!--                    <i class="stepper-check fas fa-check"></i>-->
<!--                    <span class="stepper-number">1</span>-->
<!--                </div>-->
<!--                <div class="stepper-line"></div>-->
<!--                <div class="stepper-label">-->
<!--                    <h3 class="stepper-title">Select Account Type</h3>-->
<!--                </div>-->
<!--            </div>-->

            <!-- Step 2 -->
            <div class="stepper-item" [ngClass]="{ 'current': onboardingStep === 2, 'completed': onboardingStep > 2 }">
                <div class="stepper-icon">
                    <i class="stepper-check fas fa-check"></i>
                    <span class="stepper-number">1</span>
                </div>
                <div class="stepper-line"></div>
                <div class="stepper-label">
                    <h3 class="stepper-title">Complete ACH Processing Profile</h3>
                </div>
            </div>

            <!-- Step 3 -->
            <div class="stepper-item" [ngClass]="{ 'current': onboardingStep === 3, 'completed': onboardingStep > 3 }">
                <div class="stepper-icon">
                    <i class="stepper-check fas fa-check"></i>
                    <span class="stepper-number">2</span>
                </div>
                <div class="stepper-line"></div>
                <div class="stepper-label">
                    <h3 class="stepper-title">Connect Bank Account</h3>
                </div>
            </div>

            <!-- Step 4 -->
            <div class="stepper-item" [ngClass]="{ 'current': onboardingStep === 4, 'completed': onboardingStep > 4 }">
                <div class="stepper-icon">
                    <i class="stepper-check fas fa-check"></i>
                    <span class="stepper-number">3</span>
                </div>
                <div class="stepper-line"></div>
                <div class="stepper-label">
                    <h3 class="stepper-title">Upload Documents</h3>
                </div>
            </div>

            <!-- Step 5 -->
            <div class="stepper-item" [ngClass]="{ 'current': onboardingStep === 5 }">
                <div class="stepper-icon">
                    <i class="stepper-check fas fa-check"></i>
                    <span class="stepper-number">4</span>
                </div>
                <div class="stepper-line"></div>
                <div class="stepper-label">
                    <h3 class="stepper-title">Approved! Complete Onboarding</h3>
                </div>
            </div>

        </div>
    </div>
</div>

<div *ngIf="!userService.isAdmin() && !userService.isSuperAdmin()">
    <div aria-hidden="true" class="plansDialog onboarding-modal onboarding-purchase-plan-modal modal fade animated" role="dialog" tabindex="-1">
        <div role="document" class="modal-dialog modal-lg1">
            <div class="modal-content">
                <div class=" with-gradient-ver">
                    <div class="modal-header">
                        <button aria-label="Close" class="close" data-dismiss="modal" type="button">
              <span aria-hidden="true">&times;</span>
            </button>
                    </div>
                    <div class="modal-body text-center">
                        <h4 class="modal-title" *ngIf="!userService.isHavePlan()">Select a Paynote Plan</h4>
                        <h4 class="modal-title" *ngIf="userService.isHavePlan()">Change Plan</h4>
                        <div class="modal-body">
                            <app-purchase-plan (isLoaderShow)="isLoaderShow($event)"></app-purchase-plan>
                        </div>
                    </div>
                    <div class="modal-footer">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div aria-hidden="true" class="alert-process-beneficiar-modal modal fade animated" role="dialog">
    <div role="document" class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">Please go through the steps the verification process</h4>
                <button type="button" class="close" aria-label="Close" (click)="jqueryService.closeModal('.alert-process-beneficiar-modal')">
          <span aria-hidden="true">&times;</span>
        </button>
            </div>
            <div class="modal-body">
                <span *ngIf="userService.isRequestAddBeneficialOwner">Add a beneficial owner(s) and check the status of your beneficial owner</span>
                <br>
                <span *ngIf="userService.isRequestVerifydBeneficialOwner">Verify beneficial owner(s) information</span>
                <br>
                <span *ngIf="userService.isRequestCertifyBeneficialOwner">Certify beneficial owner(s) information</span>
            </div>
            <br><br>
            <div class="modal-footer">
                <button class="slick-prev slick-arrow" type="button" (click)="jqueryService.closeModal('.alert-process-beneficiar-modal')">
          Close
        </button>
            </div>
        </div>
    </div>
</div>

<!--     -----------------------         Modal Popup Templates         --------------    -->

<ng-template #addDocumentDialog let-c="close" let-d="dismiss">
    <div class="modal-blue-bg onboarding-content">
        <div class="modal-header">
            <h4 class="modal-title">Upload Document
                <span *ngIf="(userService.isRequestVerifydBeneficialOwner || userService.isUploadBenefisiarDocument()) && objBeneficialOwner.idBeneficialOwner">
          for {{objBeneficialOwner.nameBeneficialOwner}}
        </span>
            </h4>
            <button type="button" class="close" aria-label="Close" (click)="c('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
        </div>
        <form name="form" (ngSubmit)="f.form.valid && onFileChange()" #f="ngForm">
            <div class="modal-body">
                <span class="text-capitalize" *ngIf="userService.isDwollaNeedUploadBusDoc() || userService.isNeedUploadDbaDoc()">
          Action needed:
          <span class="badge badge-warning"
                *ngIf="userService.isDwollaNeedControllerId() || userService.isDwollaNeedControllerIdAndBusinessDoc()">
                CONTROLLER ID
          </span>&nbsp;
                <span class="badge badge-warning" *ngIf="userService.isDwollaNeedBusinessDoc() || userService.isDwollaNeedControllerIdAndBusinessDoc()">
                BUSINESS DOC
          </span>&nbsp;
                <span class="badge badge-warning" *ngIf="userService.isNeedUploadDbaDoc()">
                DBA DOC
          </span> |
                <button (click)="eventClickMoreInfo()" class="mr-2 mb-2 btn btn-link" style="margin: 0; padding-bottom: 0" type="button">
            <span *ngIf="!objUserActionsComp.showMoreText">Show More</span>
            <span *ngIf="objUserActionsComp.showMoreText">Show Less</span>
          </button>
                </span>
                <div class="element-box" id="wrapp_info_need">
                    <span *ngIf="userService.isNeedUploadDbaDoc()">
            <p style="padding: 0;font-weight: bold;margin-bottom: 5px;" class="text-left">DBA Documentation <small>( <i>.jpg</i>, <i>.jpeg</i>, <i>.png</i> or <i>.pdf</i> )</small>
              <span class="badge badge-warning" style="float: right;font-weight: normal;">DBA DOC</span>
                    </p>
                    <p style="padding: 0;margin-bottom: 5px;" class="text-left">Acceptable DBA business documents include:</p>
                    <ul>
                        <li>Fictitious Business Name Statement or Certificate of Assumed Name.</li>
                    </ul>
                    </span>
                    <span *ngIf="userService.isDwollaNeedControllerId() || userService.isDwollaNeedControllerIdAndBusinessDoc()">
            <p style="padding: 0;font-weight: bold;margin-bottom: 5px;" class="text-left">Controller Documentation  <small>( <i>.jpg</i>, <i>.jpeg</i> or <i>.png</i> )</small>
              <span class="badge badge-warning" style="float: right;font-weight: normal;">CONTROLLER ID</span>
                    </p>
                    <p style="padding: 0;margin-bottom: 5px;" class="text-left">A scanned photo of the Controller's identifying document can be one of the following:</p>
                    <ul>
                        <li>passport</li>
                        <li>state issued driver's license</li>
                    </ul>
                    </span>
                    <span *ngIf="userService.isDwollaNeedBusinessDoc() || userService.isDwollaNeedControllerIdAndBusinessDoc()">
            <p style="padding: 0;font-weight: bold;margin-bottom: 5px;" class="text-left">Business Documentation <small>( <i>.jpg</i>, <i>.jpeg</i>, <i>.png</i> or <i>.pdf</i> )</small>
              <span class="badge badge-warning" style="float: right;font-weight: normal;">BUSINESS DOC</span>
                    </p>
                    <p style="padding: 0;margin-bottom: 5px;" class="text-left">
                        Acceptable business documents include:
                    </p>
                    <ul>
                        <li><em>Partnership, General Partnership:</em> EIN Letter (IRS-issued SS4 confirmation letter).</li>
                        <li><em>Limited Liability Corporation (LLC), Corporation:</em> EIN Letter (IRS-issued SS4 confirmation letter).</li>
                        <li><em>Sole Proprietorship:</em> Fictitious Business Name Statement, Certificate of Assumed Name, Business License, Sales/Use Tax License, Registration of Trade Name, IRS-issued SS4 confirmation letter, Passport or driver's license.</li>
                    </ul>
                    <p class="text-left" style="margin-bottom: 5px;">Other acceptable business documents include:</p>
                    <ul>
                        <li>Filed and stamped Articles of Organization or Incorporation</li>
                        <li>Sales/Use Tax License</li>
                        <li>Business License</li>
                        <li>Certificate of Good Standing</li>
                    </ul>
                    </span>
                    <span *ngIf="(userService.isRequestVerifydBeneficialOwner || userService.isUploadBenefisiarDocument()) && objBeneficialOwner.idBeneficialOwner">
            <p style="padding: 0;font-weight: bold;margin-bottom: 5px;" class="text-left">Owner Documentation <small>( <i>.jpg</i>, <i>.jpeg</i> or <i>.png</i> )</small>
              <span class="badge badge-warning" style="float: right;font-weight: normal;" >VERIFY OWNERS</span>
                    </p>
                    <p style="padding: 0;margin-bottom: 5px;" class="text-left">A scanned photo of the Owner's identifying document can be one of the following:</p>
                    <ul>
                        <li><em>U.S. Resident:</em> passport or state issued driver's license </li>
                        <li><em>Non-U.S. Resident:</em> passport</li>
                    </ul>
                    </span>
                </div>
                <br> The file must be either a <i>.jpg</i>, <i>.jpeg</i>, <i>.png</i> or <i>.pdf</i> up to 10MB in size.
                <div class="profile-tile">
                    <span class="profile-tile-box">
            <div class="pt-avatar-w" style="border-radius: 0px;">
              <i style="color: #aab7c8; font-size: 5em;" class="icon-feather-file-text"></i>
            </div>
          </span>
                    <div class="profile-tile-meta">
                        <ul>
                            <li>
                                <div class="row">
                                    <div class="col-sm-12 col-lg-12 col-md-12 col-xs-12">
                                        <div class="form-group" [ngClass]="{ 'has-error has-danger': !type.valid && (type.dirty || type.touched) }">
                                            <label for="">Select Document Type</label>
                                            <select name="merchantDocumentType" class="form-control" placeholder="Select Document Type" required style="width: 100%" [(ngModel)]="formDataDocument.type" #type="ngModel">
                                              <option value="reserve_agreement">Reserve Agreement</option>
                                              <option value="ach_agreement">ACH Agreement</option>
                        <option value="passport">Passport</option>
                        <option value="license">License</option>
                        <option value="idCard">IdCard</option>
                        <option value="tax_id_ein">Tax ID/EIN Letter/SS4</option>
                        <option value="filed_and_stamped_articles">Filed and stamped Articles of Organization or Incorporation</option>
                        <option value="sales_use_tax_license">Sales/Use Tax License</option>
                        <option value="business_license">Business License</option>
                        <option value="certificate_of_good_standing">Certificate of Good Standing</option>
                        <option value="dba_doc">DBA DOC</option>
                        <option value="other">Other</option>
                      </select>
                                            <div *ngIf="!type.valid && (type.dirty || type.touched)" class="help-block form-text with-errors form-control-feedback">
                                                <span *ngIf="type.errors.required">
                          Document type is required
                        </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="row">
                                    <div class="col-sm-12">
                                        <label for=""><i class="icon-feather-download"></i> Upload Document</label>
                                        <input type="file" class="form-control" name="payNoteDocUpload" id="payNoteDocUpload" accept="application/pdf, image/*" [ngClass]="{ 'errorFileType': errorFileType || errorFileSize}" type="file" (change)="onImageChangeFromFile($event)" [(ngModel)]="formDataDocument.payNoteDocUpload"
                                            #payNoteDocUpload="ngModel" required>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="slick-prev slick-arrow" (click)="c('Close click')">Cancel</button>
                <button [disabled]="loading || !f.form.valid || errorFileSize" class="slick-next slick-arrow">Upload</button>
            </div>
        </form>
    </div>
</ng-template>

<ng-template #uploadMerchantsDocuments let-c="close" let-d="dismiss">
    <div class="modal-blue-bg onboarding-content">
        <div class="modal-header">
            <h4 class="modal-title">Upload Documents
            </h4>
            <button type="button" class="close" aria-label="Close" (click)="c('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <span class="text-capitalize">
                Please upload the following documents:
            </span>
            <br> The file must be either a <i>.jpg</i>, <i>.jpeg</i>, <i>.png</i> or <i>.pdf</i> up to 10MB in size.
            <table class="table table-borderless" width="100%" role="grid" style="width: 100%;border-collapse: separate;border-spacing:0 15px;">
                <tbody style="width: 100%;">
                    <tr *ngFor="let document of docsToUpload" class="modal-tr">
                        <td>
                            <b>{{ document.name }}</b>&ast;
                            <span *ngIf="document.type === 'license'"><br>(e.g. driver's license, passport, or other government-issued ID)</span>
                        </td>
                        <td  class="text-right">
                            <div class="custom-button">
                                <div class="left-side">{{document.file_name || 'No File'}}</div>
                                <label class="right-side">
                                    <img alt="" src="assets/img/arrow-up.svg" class="upload-docs-i"/>
                                    <span class="upload-btn-text">Choose a File</span>
                                    <input type="file" class="input-doc" accept="application/pdf, image/*" (change)="onImageChangeFromFile($event, 10485760, document.type)">
                                </label>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="modal-footer">
            <button (click)="c('Close click')" class="slick-next slick-arrow">Done</button>
        </div>
    </div>
</ng-template>


<div aria-hidden="true" class="alert-verify-email-modal modal fade animated" role="dialog">
    <div role="document" class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">Please confirm your email</h4>
                <button type="button" class="close" aria-label="Close" (click)="jqueryService.closeModal('.alert-verify-email-modal')">
          <span aria-hidden="true">&times;</span>
        </button>
            </div>
            <div class="modal-body">
                <span [innerHTML]="messages.get('ACCOUNT_HAS_UNVERIFIED_EMAIL')"></span>&nbsp;
                <span class="resendEmail" (click)="profileInfoVerifyEmail()">Resend</span>
            </div>
            <br><br>
            <div class="modal-footer">
                <button class="slick-prev slick-arrow" type="button" (click)="jqueryService.closeModal('.alert-verify-email-modal')">
          Close
        </button>
            </div>
        </div>
    </div>
</div>

<div aria-hidden="true" class="alert-relogin-modal modal fade animated" role="dialog" [ngClass]="{ 'show-relogin': userService.objReloginPopup.showloginAlert }" style="z-index: 99999">
    <div role="document" class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header text-center">
                <h4 class="modal-title" style="width: 100%;">Do you want to continue?</h4>
            </div>
            <div class="modal-body">
                <p class="text-center">You Paynote session will end in <span style="font-size: 20px;"><b>{{userService.objReloginPopup.log_sec}}</b></span> seconds due to inactivity.</p>
                <p class="text-center">If you are still working, click <b>Continue</b></p>
            </div>
            <br><br>
            <div class="modal-footer">
                <button class="slick-prev slick-arrow" type="button" (click)="userService.refreshUser()">
          Continue
        </button>
                <button class="slick-next slick-arrow" type="button" (click)="userService.logout();">
          Logout
        </button>
            </div>
        </div>
    </div>
</div>

<ng-template #foundingSourcesVerifiedDialog let-c="close" let-d="dismiss">
    <div class="modal-blue-bg onboarding-content">
        <div class="modal-header">
            <h4 class="modal-title">Please verify your account</h4>
            <button type="button" class="close" aria-label="Close" (click)="c('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
        </div>
        <form name="form" (ngSubmit)="f.form.valid && verifyFundingSourcesBank()" #f="ngForm">
            <div class="modal-body">
                <div class="profile-tile">
                    <div class="profile-tile-meta" style="padding-left: 0px;">
                        <ul>
                            <li>
                                <span class="text-initial vfs-text">
                  Please enter the two micro-deposit amounts made to your <i>{{modelVerifyFundSource.data.bank}}</i> ending in
                  <i>{{modelVerifyFundSource.data.number}}</i> on or around <i>{{userService.getDateFormat(modelVerifyFundSource.data.date.date)}}</i>
                </span>
                            </li>
                            <li>
                                <br>
                            </li>
                            <li>
                                <div class="row">
                                    <div class="col-sm-6">
                                        <div class="form-group" [ngClass]="{ 'has-error has-danger': !amount1.valid && (amount1.dirty || amount1.touched) }">
                                            <label for="">Amount 1</label>
                                            <input id="Amount1" name="amount1" class="form-control" placeholder="$0.__" type="text" required [(ngModel)]="modelVerifyFundSource.amount1" #amount1="ngModel" currencyMask>
                                            <div *ngIf="!amount1.valid && (amount1.dirty || amount1.touched)" class="help-block form-text with-errors form-control-feedback">
                                                <span *ngIf="amount1.errors.required">
                          Amount 1 is required
                        </span>
                                            </div>
                                            <span class="text-initial ex-style">ex. $0.07</span>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="form-group" [ngClass]="{ 'has-error has-danger': !amount2.valid && (amount2.dirty || amount2.touched) }">
                                            <label for="">Amount 2</label>
                                            <input id="Amount2" name="amount2" class="form-control" placeholder="$0.__" type="text" required [(ngModel)]="modelVerifyFundSource.amount2" #amount2="ngModel" currencyMask>
                                            <div *ngIf="!amount2.valid && (amount2.dirty || amount2.touched)" class="help-block form-text with-errors form-control-feedback">
                                                <span *ngIf="amount2.errors.required">
                          Amount 2 is required
                        </span>
                                            </div>
                                            <span class="text-initial ex-style">ex. $0.15</span>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button class="slick-prev slick-arrow" type="button" (click)="c('Close click')">Cancel</button>
                <button [disabled]="loading || !f.form.valid" class="slick-next slick-arrow" type="submit">Verify</button>
            </div>
        </form>
    </div>
</ng-template>


<!--/******************************** STEP 1 BUSINESS - achProcessingProfileModal **********************************/-->

<div *ngIf="!userService.isAdmin() && !userService.isSuperAdmin()" aria-hidden="true" aria-modal="true" class="modal ach-processing-profile-setup-modal onboarding-modal fade animated" style="overflow-y: auto !important;" >

    <div class="modal-dialog modal-centered modal-view-width mx-auto" >
        <div class="modal-content text-center" >
            <button
                aria-label="Close"
                class="close btn-close-custom"
                data-dismiss="modal"
                type="button"
            >
                <span class="close-label"></span
                ><span class="os-icon os-icon-close"></span>
            </button>

            <div class="modal-body with-gradient-ver with-gradient-2-3" >

                    <div
                        class="onboarding-content p-3"
                        style="
                            padding-left: 20px;
                            padding-right: 40px;
                            padding-bottom: 20px;
                        "
                    >
                        <div class="row justify-content-between">
                            <div
                                class="col-sm-3 d-sm-flex align-items-center justify-content-center"
                            >
                                <img
                                    style="max-width: 228px; width: 100%; height: auto"
                                    src="assets/img/user_file.png"
                                />
                            </div>
                            <div class="col-xs-12 col-sm-8">
                                <form > <!--[formGroup]="businessTypeFormGroup"-->
                                <h3 class="modal-title text-center mt-2" style="font-size: 20px;">
                                    ACH Processing Profile
                                </h3>
                                <div class="row">
                                    <div class="col-sm-12">
                                    <div class="row">
                                        <div class="mb-3 text-left">
                                        <h5 class="font-weight-bold text-dark text-left" style="font-size: 18px;">
                                            Payment Submission Method
                                            <span class="font-weight-normal" style="font-size: 16px;"
                                            >(Select all that apply)</span
                                            >
                                        </h5>

                                        <div class="form-check form-check-inline cursor-pointer">
                                            <input
                                            class="form-check-input cursor-pointer"
                                            type="checkbox"
                                            id="invoicing"
                                            (change)="achProcessingProfile.paymentSubmissionMethods.invoicing = !achProcessingProfile.paymentSubmissionMethods.invoicing"
                                            [checked]="achProcessingProfile.paymentSubmissionMethods.invoicing"
                                            />
                                            <label class="form-check-label p-1 cursor-pointer" for="invoicing"
                                            >Invoicing</label
                                            >
                                        </div>
                                        <div class="form-check form-check-inline cursor-pointer">
                                            <input
                                            class="form-check-input cursor-pointer"
                                            type="checkbox"
                                            (change)="achProcessingProfile.paymentSubmissionMethods.api = !achProcessingProfile.paymentSubmissionMethods.api"
                                            [checked]="achProcessingProfile.paymentSubmissionMethods.api"
                                            id="api"
                                            />
                                            <label class="form-check-label p-1 cursor-pointer" for="api"
                                            >API</label
                                            >
                                        </div>
                                        <div class="form-check form-check-inline cursor-pointer">
                                            <input
                                            class="form-check-input cursor-pointer"
                                            type="checkbox"
                                            id="paymentLinks"
                                            (change)="achProcessingProfile.paymentSubmissionMethods.paymentLinks = !achProcessingProfile.paymentSubmissionMethods.paymentLinks"
                                            [checked]="achProcessingProfile.paymentSubmissionMethods.paymentLinks"
                                            />
                                            <label class="form-check-label p-1 cursor-pointer" for="paymentLinks"
                                            >Payment Links</label
                                            >
                                        </div>
                                        <div class="form-check form-check-inline cursor-pointer">
                                            <input
                                            class="form-check-input cursor-pointer"
                                            type="checkbox"
                                            id="onlineCheckout"
                                            (change)="achProcessingProfile.paymentSubmissionMethods.onlineCheckout = !achProcessingProfile.paymentSubmissionMethods.onlineCheckout"
                                            [checked]="achProcessingProfile.paymentSubmissionMethods.onlineCheckout"
                                            />
                                            <label
                                            class="form-check-label p-1 cursor-pointer"
                                            for="onlineCheckout"
                                            >Online Checkout</label
                                            >
                                        </div>
                                        </div>

                                        <div class="mb-3">
                                            <h5 class="font-weight-bold text-dark text-left" style="font-size: 18px;">Transaction Types</h5>
                                            <div class="form-check form-check-inline cursor-pointer">
                                                <input
                                                class="form-check-input cursor-pointer"
                                                type="radio"
                                                name="transactionType"
                                                id="b2b"
                                                [(ngModel)]="achProcessingProfile.transactionType"
                                                value="b2b"
                                                />
                                                <label class="form-check-label p-1 cursor-pointer" for="b2b"
                                                >B2B</label
                                                >
                                            </div>
                                            <div class="form-check form-check-inline cursor-pointer">
                                                <input
                                                class="form-check-input cursor-pointer"
                                                type="radio"
                                                name="transactionType"
                                                id="b2c"
                                                [(ngModel)]="achProcessingProfile.transactionType"
                                                value="b2c"
                                                />
                                                <label class="form-check-label p-1 cursor-pointer" for="b2c"
                                                >B2C</label
                                                >
                                            </div>
                                            <div class="form-check form-check-inline cursor-pointer">
                                                <input
                                                class="form-check-input cursor-pointer"
                                                type="radio"
                                                name="transactionType"
                                                id="both"
                                                [(ngModel)]="achProcessingProfile.transactionType"
                                                value="both"
                                                />
                                                <label class="form-check-label p-1 cursor-pointer" for="both"
                                                >Both</label
                                                >
                                            </div>
                                        </div>

                                        <div class="mb-3 text-left">
                                        <h5 class="font-weight-bold text-dark text-left" style="font-size: 18px;">
                                            Solutions Requested
                                            <span class="font-weight-normal" style="font-size: 16px;"
                                            >(Select all that apply)</span
                                            >
                                        </h5>
                                        <div class="form-check-inline cursor-pointer">
                                            <input
                                            class="form-check-input cursor-pointer"
                                            type="checkbox"
                                            id="debit"
                                            (change)="achProcessingProfile.solutionsRequested.debit = !achProcessingProfile.solutionsRequested.debit"
                                            [checked]="achProcessingProfile.solutionsRequested.debit"
                                            />
                                            <label class="form-check-label p-1 cursor-pointer" for="debit"
                                            >Debit</label
                                            >
                                        </div>
                                        <div class="form-check-inline cursor-pointer">
                                            <input
                                            class="form-check-input cursor-pointer"
                                            type="checkbox"
                                            id="credit"
                                            (change)="achProcessingProfile.solutionsRequested.credit = !achProcessingProfile.solutionsRequested.credit"
                                            [checked]="achProcessingProfile.solutionsRequested.credit"
                                            />
                                            <label class="form-check-label p-1 cursor-pointer" for="credit"
                                            >Credit</label
                                            >
                                        </div>
                                        <div class="form-check-inline cursor-pointer">
                                            <input
                                            class="form-check-input cursor-pointer"
                                            type="checkbox"
                                            id="reccuring"
                                            [checked]="achProcessingProfile.solutionsRequested.reccuring"
                                            (change)="achProcessingProfile.solutionsRequested.reccuring = !achProcessingProfile.solutionsRequested.reccuring"
                                            />
                                            <label class="form-check-label p-1 cursor-pointer" for="reccuring"
                                            >Recurring</label
                                            >
                                        </div>
                                        <div class="form-check-inline cursor-pointer">
                                            <input
                                            class="form-check-input cursor-pointer"
                                            type="checkbox"
                                            id="marketplace"
                                            (change)="achProcessingProfile.solutionsRequested.marketplace = !achProcessingProfile.solutionsRequested.marketplace"
                                            [checked]="achProcessingProfile.solutionsRequested.marketplace"
                                            />
                                            <label class="form-check-label p-1 cursor-pointer" for="marketplace"
                                            >Marketplace</label
                                            >
                                        </div>
                                        <div class="form-check-inline cursor-pointer">
                                            <input
                                            class="form-check-input cursor-pointer"
                                            type="checkbox"
                                            id="online-wallet"
                                            (change)="achProcessingProfile.solutionsRequested.onlineWallet = !achProcessingProfile.solutionsRequested.onlineWallet"
                                            [checked]="achProcessingProfile.solutionsRequested.onlineWallet"
                                            />
                                            <label
                                            class="form-check-label p-1 cursor-pointer"
                                            for="online-wallet"
                                            >Online Wallet</label
                                            >
                                        </div>
                                        <div class="form-check-inline cursor-pointer">
                                            <input
                                            class="form-check-input cursor-pointer"
                                            type="checkbox"
                                            (change)="achProcessingProfile.solutionsRequested.saveDetails = !achProcessingProfile.solutionsRequested.saveDetails"
                                            [checked]="achProcessingProfile.solutionsRequested.saveDetails"
                                            id="sffd"
                                            />
                                            <label class="form-check-label p-1 cursor-pointer" for="sffd"
                                            >Save Details For Future Direct Debits</label
                                            >
                                        </div>
                                        </div>

                                        <div class="mb-3">
                                            <h5 class="font-weight-bold text-dark text-left" style="font-size: 18px;">Primary Use Case: </h5>
                                            <div class="form-check form-check-inline cursor-pointer">
                                                <input
                                                class="form-check-input cursor-pointer"
                                                type="radio"
                                                name="primary_use_case"
                                                id="send_credits"
                                                [(ngModel)]="achProcessingProfile.primary_use_case"
                                                value="send_credits"
                                                />
                                                <label class="form-check-label p-1 cursor-pointer" for="send_credits"
                                                >Send ACH Credits</label
                                                >
                                            </div>
                                            <div class="form-check form-check-inline cursor-pointer">
                                                <input
                                                class="form-check-input cursor-pointer"
                                                type="radio"
                                                name="primary_use_case"
                                                id="pull_debits"
                                                [(ngModel)]="achProcessingProfile.primary_use_case"
                                                value="pull_debits"
                                                />
                                                <label class="form-check-label p-1 cursor-pointer" for="pull_debits"
                                                >Pull ACH Debits</label
                                                >
                                            </div>
                                            <div class="form-check form-check-inline cursor-pointer">
                                                <input
                                                class="form-check-input cursor-pointer"
                                                type="radio"
                                                name="primary_use_case"
                                                id="credits_and_debits"
                                                [(ngModel)]="achProcessingProfile.primary_use_case"
                                                value="credits_and_debits"
                                                />
                                                <label class="form-check-label p-1 cursor-pointer" for="credits_and_debits"
                                                >Credits and Debits</label
                                                >
                                            </div>
                                        </div>

                                        <div class="mb-3">
                                            <h5 class="font-weight-bold text-dark text-left" style="font-size: 18px;">What is your average transaction size in USD? </h5>
                                            <div class="form-check form-check-inline cursor-pointer">
                                                <input
                                                class="form-check-input cursor-pointer"
                                                type="radio"
                                                name="average_transaction_size"
                                                id="less_than_100"
                                                [(ngModel)]="achProcessingProfile.average_transaction_size"
                                                value="less_than_100"
                                                />
                                                <label class="form-check-label p-1 cursor-pointer" for="less_than_100"
                                                >Less than $100</label
                                                >
                                            </div>
                                            <div class="form-check form-check-inline cursor-pointer">
                                                <input
                                                class="form-check-input cursor-pointer"
                                                type="radio"
                                                name="average_transaction_size"
                                                id="100_to_500"
                                                [(ngModel)]="achProcessingProfile.average_transaction_size"
                                                value="100_to_500"
                                                />
                                                <label class="form-check-label p-1 cursor-pointer" for="100_to_500"
                                                >$100 to $500</label
                                                >
                                            </div>
                                            <div class="form-check form-check-inline cursor-pointer">
                                                <input
                                                class="form-check-input cursor-pointer"
                                                type="radio"
                                                name="average_transaction_size"
                                                id="500_to_2500"
                                                [(ngModel)]="achProcessingProfile.average_transaction_size"
                                                value="500_to_2500"
                                                />
                                                <label class="form-check-label p-1 cursor-pointer" for="500_to_2500"
                                                >$500 to $2,500</label
                                                >
                                            </div>
                                            <div class="form-check form-check-inline cursor-pointer">
                                                <input
                                                class="form-check-input cursor-pointer"
                                                type="radio"
                                                name="average_transaction_size"
                                                id="more_than_2500"
                                                [(ngModel)]="achProcessingProfile.average_transaction_size"
                                                value="more_than_2500"
                                                />
                                                <label class="form-check-label p-1 cursor-pointer" for="more_than_2500"
                                                >More than $2,500 </label
                                                >
                                            </div>
                                        </div>

                                        <div class="mb-3">
                                            <h5 class="font-weight-bold text-dark text-left" style="font-size: 18px;">What is your total monthly volume? </h5>
                                            <div class="form-check form-check-inline cursor-pointer">
                                                <input
                                                class="form-check-input cursor-pointer"
                                                type="radio"
                                                name="total_monthly_volume"
                                                id="startup"
                                                [(ngModel)]="achProcessingProfile.total_monthly_volume"
                                                value="startup"
                                                />
                                                <label class="form-check-label p-1 cursor-pointer" for="startup"
                                                >Startup</label
                                                >
                                            </div>
                                            <div class="form-check form-check-inline cursor-pointer">
                                                <input
                                                class="form-check-input cursor-pointer"
                                                type="radio"
                                                name="total_monthly_volume"
                                                id="1000_to_5000"
                                                [(ngModel)]="achProcessingProfile.total_monthly_volume"
                                                value="1000_to_5000"
                                                />
                                                <label class="form-check-label p-1 cursor-pointer" for="1000_to_5000"
                                                >$10,000 to $50,000</label
                                                >
                                            </div>
                                            <div class="form-check form-check-inline cursor-pointer">
                                                <input
                                                class="form-check-input cursor-pointer"
                                                type="radio"
                                                name="total_monthly_volume"
                                                id="51000_to_100000"
                                                [(ngModel)]="achProcessingProfile.total_monthly_volume"
                                                value="51000_to_100000"
                                                />
                                                <label class="form-check-label p-1 cursor-pointer" for="51000_to_100000"
                                                >$51,000 to $100,000</label
                                                >
                                            </div>
                                            <div class="form-check form-check-inline cursor-pointer">
                                                <input
                                                class="form-check-input cursor-pointer"
                                                type="radio"
                                                name="total_monthly_volume"
                                                id="101000_to_500000"
                                                [(ngModel)]="achProcessingProfile.total_monthly_volume"
                                                value="101000_to_500000"
                                                />
                                                <label class="form-check-label p-1 cursor-pointer" for="101000_to_500000"
                                                >$101,000 to $500,000</label
                                                >
                                            </div>
                                            <div class="form-check form-check-inline cursor-pointer">
                                                <input
                                                class="form-check-input cursor-pointer"
                                                type="radio"
                                                name="total_monthly_volume"
                                                id="501000_to_1000000"
                                                [(ngModel)]="achProcessingProfile.total_monthly_volume"
                                                value="501000_to_1000000"
                                                />
                                                <label class="form-check-label p-1 cursor-pointer" for="501000_to_1000000"
                                                >$501,000 to $1,000,000</label
                                                >
                                            </div>
                                            <div class="form-check form-check-inline cursor-pointer">
                                                <input
                                                class="form-check-input cursor-pointer"
                                                type="radio"
                                                name="total_monthly_volume"
                                                id="more_than_1000000"
                                                [(ngModel)]="achProcessingProfile.total_monthly_volume"
                                                value="more_than_1000000"
                                                />
                                                <label class="form-check-label p-1 cursor-pointer" for="more_than_1000000"
                                                >$1,000,000+</label
                                                >
                                            </div>
                                            <div class="form-check form-check-inline cursor-pointer" *ngIf="isDisabledNextStepFromACHProcessingProfile()">
                                                <label class="form-check-label p-1 cursor-pointer" style="padding: 0px !important;margin-top: 15px;text-decoration: underline;">
                                                    Please answer all the questions to complete your Paynote profile.
                                                </label>
                                            </div>
                                        </div>


                                        <button
                                        class="btn btn-primary btn-rounded"
                                        style="margin-left: auto"
                                        [disabled]="isDisabledNextStepFromACHProcessingProfile()"
                                        (click)="selectVerifiedAccountType()"
                                        >
                                        <span style="margin-right: 5px">Next </span>
                                        <svg
                                            fill="#fff"
                                            width="20px"
                                            viewBox="0 0 448 237"
                                            version="1.1"
                                            xmlns="http://www.w3.org/2000/svg"
                                            xmlns:xlink="http://www.w3.org/1999/xlink"
                                            xml:space="preserve"
                                            xmlns:serif="http://www.serif.com/"
                                            style="
                                            fill-rule: evenodd;
                                            clip-rule: evenodd;
                                            stroke-linejoin: round;
                                            stroke-miterlimit: 2;
                                            "
                                        >
                                            <g transform="matrix(1,0,0,1,0,-138)">
                                            <path
                                                d="M313.941,216L12,216C5.373,216 0,221.373 0,228L0,284C0,290.627 5.373,296 12,296L313.941,296L313.941,342.059C313.941,363.441 339.792,374.149 354.912,359.03L440.971,272.971C450.344,263.598 450.344,248.402 440.971,239.03L354.912,152.971C339.793,137.852 313.941,148.56 313.941,169.942L313.941,216Z"
                                                style="fill-rule: nonzero"
                                            />
                                            </g>
                                        </svg>
                                        </button>
                                    </div>
                                    </div>
                                </div>
                                <!-- <div
                                    class="alert alert-danger"
                                    role="alert"
                                    *ngIf="errorService.isError"
                                    [innerHTML]="errorService.errorMessage"
                                ></div> -->
                                </form>
                            </div>
                        </div>
                    </div>

            </div>
        </div>
    </div>
</div>

<ng-template #verifyPhoneCodeDialog let-c="close" let-d="dismiss">
    <div class="modal-blue-bg onboarding-content">
        <div class="modal-header">
            <h4 class="modal-title">Phone Verification</h4>
        </div>
        <div class="modal-body">
            <form name="form2FA" (ngSubmit)="f2FA.form.valid" #f2FA="ngForm" autocomplete="off" [formGroup]="verifyPhoneForm">
                <div class="form-group" [ngClass]="{ 'has-error has-danger': verifyPhoneForm.get('phone_pin').errors
             && (verifyPhoneForm.get('phone_pin').dirty || verifyPhoneForm.get('phone_pin').touched) }">
                    <label for="phone_pin">2FA Code</label>
                    <input name="phone_pin" class="form-control" placeholder="Code" type="text" maxlength="6" appNumbers formControlName="phone_pin" required id="phone_pin">
                    <div *ngIf="verifyPhoneForm.get('phone_pin').errors &&
            (verifyPhoneForm.get('phone_pin').dirty || verifyPhoneForm.get('phone_pin').touched)" class="help-block form-text with-errors form-control-feedback">
                        <span *ngIf="verifyPhoneForm.get('phone_pin').errors.required">
              Code is required
            </span>
                        <span *ngIf="verifyPhoneForm.get('phone_pin').errors.minlength">
              Code must be at least 6 digits long.
            </span>
                    </div>
                </div>
                <button style="padding-left: 0;" [disabled]="loading" class="btn btn-link" (click)="callAction({action:'verify_phone_code'})">Resend Code</button>
                <button type="submit" [disabled]="loading || !verifyPhoneForm.valid" class="btn btn-primary btn-rounded pull-right" (click)="verifyPhoneCode()">Submit & Verify</button>
            </form>
        </div>
        <div class="modal-footer">

        </div>
    </div>
</ng-template>

<ng-template #verifyVoiceCodeDialog let-c="close" let-d="dismiss" let-create="addFundSources">
    <div class="modal-blue-bg onboarding-content">
        <div class="modal-header">
            <h4 class="modal-title">Additional verification</h4>
            <!--      <button type="button" class="close" aria-label="Close" (click)="c('Cross click')">
                    <span aria-hidden="true">&times;</span>
            </button>-->
        </div>
        <div class="modal-body">
            <form name="form2FA" (ngSubmit)="f2FA.form.valid && verifyVoiceCode()" #f2FA="ngForm" autocomplete="off" [formGroup]="verifyPhoneForm">
                <div class="form-group" [ngClass]="{ 'has-error has-danger': verifyPhoneForm.get('phone_pin').errors
             && (verifyPhoneForm.get('phone_pin').dirty || verifyPhoneForm.get('phone_pin').touched) }">
                    <label for="phone_pin">Voice Code</label>
                    <input name="phone_pin" class="form-control" placeholder="Code" type="text" maxlength="6" appNumbers formControlName="phone_pin" required id="phone_pin">
                    <div *ngIf="verifyPhoneForm.get('phone_pin').errors &&
            (verifyPhoneForm.get('phone_pin').dirty || verifyPhoneForm.get('phone_pin').touched)" class="help-block form-text with-errors form-control-feedback">
                        <span *ngIf="verifyPhoneForm.get('phone_pin').errors.required">
              Code is required
            </span>
                        <span *ngIf="verifyPhoneForm.get('phone_pin').errors.minlength">
              Code must be at least 6 digits long.
            </span>
                    </div>
                </div>
                <button style="padding-left: 0;" [disabled]="loading" class="btn btn-link" (click)="callAction({action:'verify_voice_code'})">Resend Voice Code
        </button>
                <button type="submit" [disabled]="loading || !verifyPhoneForm.valid" class="btn btn-primary btn-rounded pull-right">Submit & Verify
        </button>
            </form>
        </div>
        <div class="modal-footer">

        </div>
    </div>
</ng-template>
<div aria-hidden="true" aria-modal="true" class="funding-source-setup-modal modal fade animated " style="overflow-y: auto !important;" role="dialog" tabindex="-1"
    [ngClass]="{ 'show-on-load': isShowFundingSourceSetupOnLoad() || isClientNotHaveFundingSource }">
    <div class="modal-dialog modal-centered" role="document">
        <div class="modal-content onboarding-content">
            <div class="modal-header">
                <button aria-label="Close" class="close" data-dismiss="modal" type="button">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="onboarding-media">
                <img src="assets/img/undraw-vault-9-cmw.png" srcset="assets/img/undraw-vault-9-cmw@2x.png 2x, assets/img/undraw-vault-9-cmw@3x.png 3x" class="addFundingSourceImg">
            </div>
            <div class="modal-body addFundingSourceBox">
                <div class="modal-body">
                    <div class="onboarding-content with-gradient">
                        <h4 class="modal-title">{{messages.get('TITLE_ADD_FUNDING_SOURCE')}}</h4>
                        <p>Choose a verification method to confirm your bank details and add your bank account.</p>
                        <div    >
                            <div>
                                <div class="form-group">
                                    <button id="instantVerificationBtn" class="btn btn-primary btn-rounded" placement="top" ngbTooltip="Instant Verification" style="width: 152px !important;" [disabled]="loading" type="button" (click)="openPlaidModal()">
                    Instant Verification
                  </button>
                                </div>
                                <div class="form-group">
                                    <label>
                                        Quickly link your bank by using Plaid’s secure credential-based login.
                                    </label>
                                </div>
                            </div>
<!--                            <div class="col-sm-6">-->
<!--                                <div class="form-group">-->
<!--                                    <button id="manualVerificationBtn" class="btn btn-primary btn-rounded" type="button" [disabled]="loading" style="width: 152px !important;" (click)="closePopupForAddFoundSources(true); openDialog(contentAddFundSource);" placement="top" ngbTooltip="Micro-Deposits">-->
<!--                    Micro-Deposits-->
<!--                  </button>-->
<!--                                </div>-->
<!--                                <div class="form-group">-->
<!--                                    <label>-->
<!--                                        Manually enter your routing and account numbers. This process typically takes 2-3 business days.-->
<!--                                    </label>-->
<!--                                </div>-->
<!--                            </div>-->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!--**********************************************UPLOAD_CURRENT_BUSINESS_BANK_STATEMENT************************************************-->

<ng-template #uploadBankStatement let-c="close" let-d="dismiss" style="overflow-y: auto !important;">
  <div class="manual-verification">
    <div class="modal-header">
      <button type="button" class="close" aria-label="Close" (click)="c('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="manual-verification-title">
        <h4 class="modal-title">Upload Current Business Bank Statement</h4>
      </div>
      <div class="modal-body">
        <form name="form" (ngSubmit)="f.form.valid && onFileChange('bank_statement')" #f="ngForm">
          <div class="modal-body">
            <br> The file must be either a <i>.jpg</i>, <i>.jpeg</i>, <i>.png</i> or <i>.pdf</i> up to 2MB in size.
            <div class="profile-tile">
                    <span class="profile-tile-box">
                        <div class="pt-avatar-w" style="border-radius: 0px;">
                            <i style="color: #aab7c8; font-size: 5em;" class="icon-feather-file-text"></i>
                        </div>
                    </span>
              <div class="profile-tile-meta">
                <ul>
                  <li><!-- bank_statement -->
                    <div class="row">
                      <div class="col-sm-12">
                        <label for=""><i class="icon-feather-download"></i> Upload Document</label>
                        <input type="file" class="form-control" name="payNoteDocUpload" id="payNoteDocUpload" accept="application/pdf, image/*" [ngClass]="{ 'errorFileType': errorFileType || errorFileSize}" type="file" (change)="onImageChangeFromFile($event, 2000000)" [(ngModel)]="formDataDocument.payNoteDocUpload"
                               #payNoteDocUpload="ngModel" required>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="slick-prev slick-arrow" (click)="c('Close click')">Cancel</button>
            <button [disabled]="loading || !f.form.valid || errorFileSize" class="slick-next slick-arrow">Upload</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</ng-template>

<!--**********************************************MANUALLY_VERIFY_YOUR_BANK_ACCOUNT************************************************-->

<ng-template #contentAddFundSource let-c="close" let-d="dismiss">
    <div class="manual-verification">
        <div class="modal-header">
            <button type="button" class="close" aria-label="Close" (click)="c('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
        </div>
        <div class="modal-body">
            <div class="manual-verification-title">
                <h4 class="modal-title">{{messages.get('MANUALLY_VERIFY_YOUR_BANK_ACCOUNT')}}</h4>
            </div>
            <div class="modal-body">
                <p class="mf-text">{{messages.get('ENTER_YOUR_BANK_DETAILS_BELOW_AND_SUBMIT')}}</p>
                <p class="mf-text">{{messages.get('TO_VERIFY_YOUR_BANK_ACCOUNT_MANUALLY')}}</p>
                <p class="mf-text">{{messages.get('TO_AVOID_ANY_OVERDRAFT_OR_OTHER_BANK')}}</p>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <form name="form" #f="ngForm" class="form-validation" autocomplete="nope">
                    <div class="row">
                        <div class="col-sm-6">
                            <div class="form-group" [ngClass]="{'has-error has-danger': !routingNumber.valid && (routingNumber.dirty || routingNumber.touched)}">
                                <label for="">Routing Number</label>
                                <input appNumbers class="form-control" placeholder="Routing Number" type="text" name="routingNumber" required minlength="9" maxlength="9" autocomplete="off" pattern="[\*0-9]*" [(ngModel)]="modelNewFundSource.routingNumber" #routingNumber="ngModel" (change)="getBankRouting()"
                                    (keyup)="utility.onChangeExpInput($event, this.modelNewFundSource.routingNumber)">
                                <div class="help-block form-text text-muted form-control-feedback" *ngIf="!routingNumber.valid && (routingNumber.dirty || routingNumber.touched)">
                                    <span *ngIf="routingNumber.errors.required">
                        Routing Number is required
                      </span>
                                    <span *ngIf="routingNumber.errors.minlength">
                        Routing Number must be at least 9 digits long.
                      </span>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label>Bank Name</label>
                                <!--<div *ngIf="modelNewFundSource.name" class="mv-bankName" [ngClass]="{'red': modelNewFundSource.error}">{{modelNewFundSource.name}}</div>-->
                                <div *ngIf="modelNewFundSource.name && modelNewFundSource.error" class="mv-bankName red">{{modelNewFundSource.name}}</div>
                                <div *ngIf="modelNewFundSource.name && modelNewFundSource.success" class="mv-bankName">{{modelNewFundSource.name}}</div>
                            </div>
                            <div class="form-group" style="display: none;">
                                <label>Account Type</label>
                                <select id="account-type" name="accountType" class="form-control" [(ngModel)]="modelNewFundSource.bankAccountType" name="bankAccountType">
                  <option value="checking">Checking</option>
                  <option value="savings">Savings</option>
                </select>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-sm-6">
                            <div class="form-group" [ngClass]="{'has-error has-danger': !accountNumber.valid && (accountNumber.dirty || accountNumber.touched)}">
                                <label>Account Number</label>
                                <input appNumbers class="form-control" placeholder="e.g. 489729074589" type="text" pattern="[\*0-9]*" name="accountNumber" minlength="4" maxlength="17" required autocomplete="off" [(ngModel)]="modelNewFundSource.accountNumber" #accountNumber="ngModel"
                                    (keyup)="utility.onChangeExpInput($event, this.modelNewFundSource.accountNumber)">
                                <div class="help-block form-text text-muted form-control-feedback" *ngIf="!accountNumber.valid && (accountNumber.dirty || accountNumber.touched)">
                                    <span *ngIf="accountNumber.errors.required">
                        Account Number is required
                      </span>
                                    <span *ngIf="accountNumber.errors.minlength">
                        Account Number must be at least 4 digits long.
                      </span>
                                    <span *ngIf="accountNumber.errors.maxlength">
                        Account Number must be at max 17 digits long.
                      </span>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="form-group" [ngClass]="{'has-error has-danger': !re_account_number.valid && (re_account_number.dirty || re_account_number.touched)}">
                                <label>Confirm Account Number</label>
                                <input appNumbers class="form-control" placeholder="e.g. 489729074589" type="text" required name="re_account_number" minlength="4" maxlength="17" autocomplete="off" pattern="[\*0-9]*" [(ngModel)]="modelNewFundSource.re_account_number" #re_account_number="ngModel"
                                    (keyup)="utility.onChangeExpInput($event, this.modelNewFundSource.re_account_number)">
                                <div class="help-block form-text text-muted form-control-feedback" *ngIf="!re_account_number.valid && (re_account_number.dirty || re_account_number.touched)">
                                    <span *ngIf="re_account_number.errors.required">
                      Confirm Account Number is required
                    </span>
                                    <span *ngIf="re_account_number.errors.minlength">
                      Account Number must be at least 4 digits long.
                    </span>
                                    <span *ngIf="re_account_number.errors.maxlength">
                      Account Number must be at max 17 digits long.
                    </span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-sm-12">
                            <div class="form-group">
                                <img style="width: 100%;" class="brnumber-img" src="assets/img/us-bank-routing-numbers.png">
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <div class="modal-footer">
            <button class="slick-prev slick-arrow" type="button" (click)="closeModal(); jqueryService.showModal('.funding-source-setup-modal.modal', {backdrop: 'static', keyboard: false, showClose: true})">
        Cancel
      </button>
            <button [disabled]="loading || !f.form.valid" class="slick-next slick-arrow" type="submit" (click)="addFundSources()">Submit & Verify
      </button>
        </div>
    </div>
</ng-template>

<!--*************************************** ADD BENFICIAL OWNER POPUP *********************************************************-->

<ng-template #addBeneficialOwner let-c="close" let-d="dismiss" let-create="addFundSources">
    <div class="modal-blue-bg onboarding-content addBeneficialOwner">
        <div class="modal-header">
            <h4 class="modal-title">Add Owner Information</h4>
            <button type="button" class="close" aria-label="Close" (click)="c('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
        </div>
        <form name="form" autocomplete="nope" (ngSubmit)="addNewBeneficialOwner()" [formGroup]="addBeneficialFormGroup" class="form-validation">
            <div class="modal-body">
                <div class="modal-body">
                    <div class="row">
                        <div class="col-sm-6">
                            <div class="form-group" [ngClass]="{'has-error has-danger': addBeneficialFormGroup.get('firstName').errors
                     && (addBeneficialFormGroup.get('firstName').dirty || addBeneficialFormGroup.get('firstName').touched)}">
                                <label>First Name *</label>
                                <input class="form-control" placeholder="First Name" type="text" name="firstName" formControlName="firstName" required>
                                <div class="help-block form-text with-errors text-muted form-control-feedback" *ngIf="addBeneficialFormGroup.get('firstName').errors
                       && (addBeneficialFormGroup.get('firstName').dirty || addBeneficialFormGroup.get('firstName').touched)">
                                    <span *ngIf="addBeneficialFormGroup.get('firstName').errors.required">
                      First Name is required
                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="form-group" [ngClass]="{'has-error has-danger': addBeneficialFormGroup.get('lastName').errors
                     && (addBeneficialFormGroup.get('lastName').dirty || addBeneficialFormGroup.get('lastName').touched)}">
                                <label>Last Name *</label>
                                <input class="form-control" placeholder="Last Name" type="text" name="lastName" formControlName="lastName" required>
                                <div class="help-block form-text text-muted form-control-feedback" *ngIf="addBeneficialFormGroup.get('lastName').errors
                       && (addBeneficialFormGroup.get('lastName').dirty || addBeneficialFormGroup.get('lastName').touched)">
                                    <span *ngIf="addBeneficialFormGroup.get('lastName').errors.required">
                      Last Name is required
                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="form-group dobirth" [ngClass]="{'has-error has-danger': addBeneficialFormGroup.get('dateOfBirth').errors
                                && (addBeneficialFormGroup.get('dateOfBirth').dirty || addBeneficialFormGroup.get('dateOfBirth').touched)}">
                                <label>Date of birth *</label>
                                <div style="display: inline-flex;">
                                    <input matInput [matDatepicker]="controllerDatepicker" formControlName="dateOfBirth" placeholder="Date Of Birth" required autocomplete="off" class="form-control">
                                    <mat-datepicker-toggle matSuffix [for]="controllerDatepicker"></mat-datepicker-toggle>
                                    <mat-datepicker #controllerDatepicker></mat-datepicker>
                                </div>
                                <small>MM/DD/YYYY</small>
                                <div class="help-block form-text text-muted form-control-feedback" *ngIf="addBeneficialFormGroup.get('dateOfBirth').errors &&
                                    addBeneficialFormGroup.get('dateOfBirth').dirty &&
                                      addBeneficialFormGroup.get('dateOfBirth').errors.validBirthDate">
                                    Must be 18 years or older
                                </div>
                                <div class="help-block form-text text-muted form-control-feedback" *ngIf="addBeneficialFormGroup.get('dateOfBirth').errors &&
                                          addBeneficialFormGroup.get('dateOfBirth').dirty &&
                                                  addBeneficialFormGroup.get('dateOfBirth').errors.required">
                                    Date Of Birth is required
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="form-group" [ngClass]="{'has-error has-danger': addBeneficialFormGroup.get('citizenshipStatus').errors
                 && (addBeneficialFormGroup.get('citizenshipStatus').dirty || addBeneficialFormGroup.get('citizenshipStatus').touched)}">
                                <label>Citizenship Status *</label>
                                <select style="width: 100%" id="citizenshipStatus" required class="form-control" name="citizenshipStatus" formControlName="citizenshipStatus" (ngModelChange)=" changeCitizenshipStatus($event)">
                  <option *ngFor="let CitizenshipStatus of arrCitizenshipStatus" [value]="CitizenshipStatus.value">{{ CitizenshipStatus.name }}</option>
                </select>
                                <div class="help-block form-text text-muted form-control-feedback" *ngIf="addBeneficialFormGroup.get('citizenshipStatus').errors
                   && (addBeneficialFormGroup.get('citizenshipStatus').dirty || addBeneficialFormGroup.get('citizenshipStatus').touched)">
                                    <span *ngIf="addBeneficialFormGroup.get('citizenshipStatus').errors.required">
                  Citizenship Status is required
                </span>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6" *ngIf="!isUSA">
                            <div class="form-group" [ngClass]="{'has-error has-danger': addBeneficialFormGroup.get('number').errors
                   && (addBeneficialFormGroup.get('number').dirty || addBeneficialFormGroup.get('number').touched)}">
                                <label>Passport number *</label>
                                <input class="form-control" placeholder="Passport number" type="text" name="number" formControlName="number" required>
                                <div class="help-block form-text text-muted form-control-feedback" *ngIf="addBeneficialFormGroup.get('number').errors
                   && (addBeneficialFormGroup.get('number').dirty || addBeneficialFormGroup.get('number').touched)">
                                    <span *ngIf="addBeneficialFormGroup.get('number').errors.required">
                  Passport number is required
                </span>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6" *ngIf="!isUSA">
                            <div class="form-group" [ngClass]="{'has-error has-danger': addBeneficialFormGroup.get('countryPassport').errors
                 && (addBeneficialFormGroup.get('countryPassport').dirty || addBeneficialFormGroup.get('countryPassport').touched)}">
                                <label>Country of Passport Issued *</label>
                                <select style="width: 100%" id="countryPassport" required class="form-control" name="countryPassport" formControlName="countryPassport">
                  <option *ngFor="let country of arrCountriesPassport"
                          [value]="country.code">{{ country.name }}</option>
                </select>
                                <div class="help-block form-text text-muted form-control-feedback" *ngIf="addBeneficialFormGroup.get('countryPassport').errors
                   && (addBeneficialFormGroup.get('countryPassport').dirty || addBeneficialFormGroup.get('countryPassport').touched)">
                                    <span *ngIf="addBeneficialFormGroup.get('countryPassport').errors.required">
                  Country of Passport Issued is required
                </span>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6" *ngIf="isUSA">
                            <div class="form-group" [ngClass]="{'has-error has-danger': addBeneficialFormGroup.get('ssn').errors
                && (addBeneficialFormGroup.get('ssn').dirty || addBeneficialFormGroup.get('ssn').touched)}">
                                <label>Social Security Number *</label>
                                <input class="form-control" placeholder="Social Security Number" type="text" name="ssn" formControlName="ssn" required [textMask]="{mask: utility.maskFullSsn, guide: false}" minlength="11">
                                <div class="help-block form-text text-muted form-control-feedback" *ngIf="addBeneficialFormGroup.get('ssn').errors
                       && (addBeneficialFormGroup.get('ssn').dirty || addBeneficialFormGroup.get('ssn').touched)">
                                    <span *ngIf="addBeneficialFormGroup.get('ssn').errors.required">
                    Social Security Number is required
                  </span>
                                </div>
                            </div>
                        </div>

                        <div class="col-sm-6">
                            <div class="form-group" [ngClass]="{'has-error has-danger': addBeneficialFormGroup.get('address1').errors
                   && (addBeneficialFormGroup.get('address1').dirty || addBeneficialFormGroup.get('address1').touched)}">
                                <label>Address *</label>
                                <input class="form-control" placeholder="Address" type="text" name="address1" formControlName="address1" required>
                                <div class="help-block form-text text-muted form-control-feedback" *ngIf="addBeneficialFormGroup.get('address1').errors
                     && (addBeneficialFormGroup.get('address1').dirty || addBeneficialFormGroup.get('address1').touched)">
                                    <span *ngIf="addBeneficialFormGroup.get('address1').errors.required">
                    Address is required
                  </span>
                                </div>
                            </div>
                        </div>
                        <!--            <div class="col-sm-6">-->
                        <!--              <div class="form-group" [ngClass]="{'has-error has-danger': addBeneficialFormGroup.get('address2').errors-->
                        <!--                   && (addBeneficialFormGroup.get('address2').dirty || addBeneficialFormGroup.get('address2').touched)}">-->
                        <!--                <label>Address 2</label>-->
                        <!--                <input class="form-control" placeholder="Address 2" type="text" formControlName="address2"-->
                        <!--                       name="address2">-->
                        <!--              </div>-->
                        <!--            </div>-->
                        <div class="col-sm-6">
                            <div class="form-group" [ngClass]="{'has-error has-danger': addBeneficialFormGroup.get('city').errors
                   && (addBeneficialFormGroup.get('city').dirty || addBeneficialFormGroup.get('city').touched)}">
                                <label>City *</label>
                                <input class="form-control" placeholder="City" type="text" name="city" required formControlName="city">
                                <div class="help-block form-text text-muted form-control-feedback" *ngIf="addBeneficialFormGroup.get('city').errors
                     && (addBeneficialFormGroup.get('city').dirty || addBeneficialFormGroup.get('city').touched)">
                                    <span *ngIf="addBeneficialFormGroup.get('city').errors.required">
                    City is required
                  </span>
                                </div>
                            </div>
                        </div>

                        <div class="col-sm-6">
                            <div *ngIf="isUSA" class="form-group" [ngClass]="{'has-error has-danger': addBeneficialFormGroup.get('stateProvinceRegion').errors
                && (addBeneficialFormGroup.get('stateProvinceRegion').dirty || addBeneficialFormGroup.get('stateProvinceRegion').touched)}">
                                <label>State *</label>
                                <select style="width: 100%" id="stateProvinceRegion" required class="form-control" name="stateProvinceRegion" formControlName="stateProvinceRegion">
                  <option *ngFor="let state of arrStates" [value]="state.abbreviation">{{ state.name }}</option>
                </select>
                                <div class="help-block form-text text-muted form-control-feedback" *ngIf="addBeneficialFormGroup.get('stateProvinceRegion').errors
                     && (addBeneficialFormGroup.get('stateProvinceRegion').dirty || addBeneficialFormGroup.get('stateProvinceRegion').touched)">
                                    <span *ngIf="addBeneficialFormGroup.get('stateProvinceRegion').errors.required">
                    State is required
                  </span>
                                </div>
                            </div>
                            <div *ngIf="!isUSA" class="form-group" [ngClass]="{'has-error has-danger': addBeneficialFormGroup.get('stateProvinceRegionNotUS').errors
                && (addBeneficialFormGroup.get('stateProvinceRegionNotUS').dirty || addBeneficialFormGroup.get('stateProvinceRegionNotUS').touched)}">
                                <label>State Province Region *</label>
                                <input class="form-control" id="stateProvinceRegionNotUS" placeholder="State Province Region" type="text" minlength="2" maxlength="2" appOnlyLetters style="text-transform: uppercase;" name="stateProvinceRegionNotUS" required formControlName="stateProvinceRegionNotUS">
                                <small style="font-size: 10px;">
                  Two-letter state, province, or region
                  <a href="https://en.wikipedia.org/wiki/ISO_3166-1" target="_blank">ISO</a> abbreviation code
                </small>
                                <div class="help-block form-text text-muted form-control-feedback" *ngIf="addBeneficialFormGroup.get('stateProvinceRegionNotUS').errors
                     && (addBeneficialFormGroup.get('stateProvinceRegionNotUS').dirty || addBeneficialFormGroup.get('stateProvinceRegionNotUS').touched)">
                                    <span *ngIf="addBeneficialFormGroup.get('stateProvinceRegionNotUS').errors.required">
                    State Province Region is required
                  </span>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="form-group" [ngClass]="{'has-error has-danger': addBeneficialFormGroup.get('country').errors
                   && (addBeneficialFormGroup.get('country').dirty || addBeneficialFormGroup.get('country').touched)}">
                                <label>Country <span *ngIf="!isUSA">of Residence </span>*</label>
                                <select style="width: 100%" id="country" required class="form-control" name="country" formControlName="country">
                  <option *ngFor="let country of arrCountries" [value]="country.code">{{ country.name }}</option>
                </select>
                                <div class="help-block form-text text-muted form-control-feedback" *ngIf="addBeneficialFormGroup.get('country').errors
                     && (addBeneficialFormGroup.get('country').dirty || addBeneficialFormGroup.get('country').touched)">
                                    <span *ngIf="addBeneficialFormGroup.get('country').errors.required">
                    Country is required
                  </span>
                                </div>
                            </div>
                        </div>



                        <div class="col-sm-6">
                            <div class="form-group" [ngClass]="{'has-error has-danger': objRequiredControls.beneficialAddressPostalCode && addBeneficialFormGroup.get('postalCode')
                   && addBeneficialFormGroup.get('postalCode').errors
                   && (addBeneficialFormGroup.get('postalCode').dirty || addBeneficialFormGroup.get('postalCode').touched) || false}">
                                <label>Postal Code <small *ngIf="!isUSA">(optional)</small><small *ngIf="isUSA">*</small></label>
                                <input class="form-control" placeholder="Postal Code" type="text" name="postalCode" required="{{objRequiredControls.beneficialAddressPostalCode}}}" formControlName="postalCode">
                                <small *ngIf="isUSA">US five-digit ZIP or ZIP + 4 code</small>
                                <div class="help-block form-text text-muted form-control-feedback" *ngIf="addBeneficialFormGroup.get('postalCode').errors
                     && (addBeneficialFormGroup.get('postalCode').dirty || addBeneficialFormGroup.get('postalCode').touched)">
                                    <span *ngIf="addBeneficialFormGroup.get('postalCode').errors.required">
                    Postal Code is required
                  </span>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="slick-prev slick-arrow" (click)="c('Close click')">Cancel</button>
                <button [disabled]="isLoading || !addBeneficialFormGroup.valid" class="slick-next slick-arrow">Confirm &
          Create
        </button>
            </div>
        </form>
    </div>
</ng-template>
